<template>
  <div class="wrap">
    <p class="title">预警列表</p>
    <!-- 搜索 -->
    <a-form>
      <div class="box">
        <a-form-item label="视频编号">
          <a-input v-model:value.trim="videoCode" placeholder="请输入视频编号" allowClear></a-input>
        </a-form-item>

        <a-form-item label="工单状态">
          <a-select
            v-model:value="type"
            allowClear
            placeholder="请选择工单状态"
            style="width: 200px"
          >
            <a-select-option :value="1">代办</a-select-option>
            <a-select-option :value="2">已推送</a-select-option>
            <a-select-option :value="3">已作废</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="转发平台">
          <a-select
            v-model:value="platIds"
            :maxTagCount="1"
            mode="multiple"
            allowClear
            placeholder="请选择转发平台"
            style="width: 200px"
          >
            <a-select-option :value="1">综合执法平台</a-select-option>
            <a-select-option :value="2">智慧城管平台</a-select-option>
            <a-select-option :value="3">非接触性执法平台</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="违法类型">
          <a-select
            v-model:value="illegalIds"
            :maxTagCount="1"
            mode="multiple"
            allowClear
            placeholder="请选择违法类型"
            style="width: 200px"
          >
            <a-select-option :value="item.id" v-for="(item, i) in TypesViolationList" :key="i">{{
              item.name
            }}</a-select-option>
          </a-select>
        </a-form-item>
      </div>

      <div class="box">
        <a-form-item label="案件描述">
          <a-input v-model:value.trim="description" placeholder="请输入视频编号" allowClear></a-input>
        </a-form-item>
        <a-form-item label="案发时间">
          <a-range-picker v-model:value="rangeDate" />
        </a-form-item>

        <a-form-item class="btn-group">
          <a-button type="primary" @click="search">查询</a-button>
          <a-button @click="reset">重置</a-button>
          <a-button @click="export_click">导出</a-button>
        </a-form-item>
      </div>
    </a-form>
    <!-- 表格 -->
    <a-table
      :dataSource="dataSource"
      :columns="columns"
      :pagination="{ pageSize: pageSize, current: curPage, total: total }"
      :row-key="record => record.id"
      @change="handlePageChange"
    >
      <template #idx="row">
        {{ (curPage - 1) * pageSize + row.index + 1 }}
      </template>
      <template #evidencePicture="row">
        <a-image v-if="row.record.type != 2" class="avatar" alt="证据图片" :src="row.record.evidencePicture" />
        <a-image v-else class="avatar" alt="证据图片" :src="row.record.evidencePictureRed" />
      </template>
      <template #type="row">
        {{ row.record.type == 1 ? '代办' : row.record.type == 2 ? '已推送' : '已作废' }}
      </template>
      <template #evidenceVideo="row">
        <a-button type="link" @click="view(row)" v-if="row.record.evidenceVideo">查看视频</a-button>
        <span v-else>暂无视频</span>
      </template>
      <template #action="row">
        <a-button type="link" @click="edit(row)" v-if="row.record.type == 1">编辑</a-button>
      </template>
    </a-table>
  </div>

  <!-- <a-modal :visible="visible" title="查看视频" @cancel="handleCancel" :footer="null">
    <div id="mse-video"></div>
  </a-modal> -->

  <revision-modal :title="modal.title" :class="[modal.earlyWarning ? 'earlyWarning-box' : '']">
    <template v-slot:content>
      <div v-html="modal.content"></div>
      <div v-if="modal.video" id="mse-video"></div>
      <!-- 发起预警 -->
      <div v-if="modal.earlyWarning">
        <div class="itemBox">
          <span>违法类型：</span>
          <a-select
            v-model:value="earlyWarningObj.illegalType"
            allowClear
            class="itemRight"
            placeholder="请选择违法类型"
          >
            <a-select-option :value="item.id" v-for="(item, i) in TypesViolationList" :key="i">{{
              item.name
            }}</a-select-option>
          </a-select>
        </div>
        <div class="itemBox">
          <span>案件描述：</span>
          <a-textarea
            class="itemRight"
            v-model:value="earlyWarningObj.description"
            placeholder="请输入案件描述"
            :rows="4"
          />
        </div>
        <div class="itemBox">
          <span>证据图片：</span>
          <div class="itemRight" @click="amplification">
            <img :src="earlyWarningObj.evidencePicture" />
          </div>
        </div>
        <div class="itemBox">
          <span>工单派发：</span>
          <a-select
            v-model:value="earlyWarningObj.platform"
            @change="handleChange"
            allowClear
            class="itemRight"
            placeholder="请选择工单派发系统"
          >
            <a-select-option :value="1">综合执法平台</a-select-option>
            <a-select-option :value="2">智慧城管平台</a-select-option>
            <a-select-option :value="3">非接触性执法平台</a-select-option>
          </a-select>
        </div>
        <div class="itemBox" v-show="departmencode_show">
          <span>执法大队：</span>
          <a-select
            v-model:value="earlyWarningObj.departmencode"
            allowClear
            class="itemRight"
            placeholder="请选择执法大队"
          >
            <a-select-option value="直属执法大队">直属执法大队</a-select-option>
            <a-select-option value="拙政园片区综合新政执法大队">拙政园片区综合新政执法大队</a-select-option>
            <a-select-option value="阊门片区新政执法大队">阊门片区新政执法大队</a-select-option>
            <a-select-option value="盘门片区新政执法大队">盘门片区新政执法大队</a-select-option>
            <a-select-option value="葑门片区新政执法大队">葑门片区新政执法大队</a-select-option>
            <a-select-option value="山塘片区新政执法大队">山塘片区新政执法大队</a-select-option>
            <a-select-option value="平江新城综合行政执法大队">平江新城综合行政执法大队</a-select-option>
            <a-select-option value="沧浪新城综合行政执法大队">沧浪新城综合行政执法大队</a-select-option>
            <a-select-option value="金阊新城综合行政执法大队">金阊新城综合行政执法大队</a-select-option>
            <a-select-option value="火车站大队">火车站大队</a-select-option>
          </a-select>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <a-button type="primary" v-show="modal.earlyWarning" @click="invalidate" class="button-modal">作废</a-button>
      <a-button type="primary" v-show="modal.earlyWarning" @click="ok" class="button-modal">推送</a-button>
    </template>
  </revision-modal>

  <a-drawer title="画框" placement="right" :closable="false" v-model:visible="modifyImage" width="80%">
    <div id="modifyImage">
      <div id="box-canvas">
        <canvas
          id="canvas"
          class="canvas"
          @mousedown="mouseDown($event)"
          @mouseup="mouseUp($event)"
          @mousemove="mouseMove($event)"
        ></canvas>
      </div>
    </div>
    <a-button type="primary" @click="() => (modifyImage = false)">下一步</a-button>
  </a-drawer>

  
</template>

<script>
import { defineComponent, ref, toRefs, reactive, onMounted, nextTick, provide } from 'vue';
import api from '@/services';
import moment from 'moment';
import { message } from 'ant-design-vue';
// import { Modal, message } from 'ant-design-vue';
// import HlsPlayer from 'xgplayer-hls';
import { xlsl } from '@/config';
import { FormatTime } from '@/utils/tool';
import HlsPlayer from 'xgplayer-hls.js';
import RevisionModal from '@/components/RevisionModal.vue';
const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    align: 'center',
    width: 80,
    slots: {
      customRender: 'idx'
    }
  },
  {
    title: '视频编号',
    dataIndex: 'videoCode',
    align: 'center',
    ellipsis: true
  },
  // {
  //   title: '案件编号',
  //   dataIndex: 'caseNumber',
  //   align: 'center',
  //   ellipsis: true
  // },
  {
    title: '所属部门',
    dataIndex: 'departmentName',
    align: 'center',
    ellipsis: true
  },
  {
    title: '案发时间',
    dataIndex: 'createTime',
    align: 'center'
  },
  {
    title: '转发平台',
    dataIndex: 'platformName',
    align: 'center'
  },
  {
    title: '违法类型',
    dataIndex: 'illegalTypeName',
    align: 'center'
  },
  {
    title: '案件描述',
    dataIndex: 'description',
    align: 'center',
    ellipsis: true
  },
  {
    title: '证据图片',
    dataIndex: 'evidencePicture',
    align: 'center',
    slots: {
      customRender: 'evidencePicture'
    }
  },

  {
    title: '工单状态',
    dataIndex: 'type',
    align: 'center',
    slots: {
      customRender: 'type'
    }
  },

  {
    title: '证据视频',
    dataIndex: 'evidenceVideo',
    align: 'center',
    ellipsis: true,
    slots: {
      customRender: 'evidenceVideo'
    }
  },
  {
    title: '操作',
    key: 'action',
    dataIndex: 'action',
    width: 100,
    align: 'center',
    slots: {
      customRender: 'action'
    }
  }
];
export default defineComponent({
  components: {
    RevisionModal
  },
  setup() {
    onMounted(() => {
      getWarningList();
      getTypesViolation();
    });

    const videoUrl = ref('');
    const visible = ref(false);
    provide('visible', visible);
    const modal = ref({
      title: '',
      content: ''
    });
    const playerState = reactive({
      player: {}
    });

    // table 相关变量
    const tableState = reactive({
      pageSize: 5,
      curPage: 1,
      total: 0,
      dataSource: [],
      columns
    });

    // 条件筛选表单
    const formState = reactive({
      // caseNumber: '',
      // 案发时间
      rangeDate: [],
      // 案件描述
      description: '',
      // 视频编号
      videoCode: '',
      // 违法类型
      illegalIds: [],
      // 转发平台
      platIds: [],
      // 工单状态
      type: null
    });

    // 获取预警列表 table data
    const getWarningList = async () => {
      const params = {
        ...formState,
        startTime: formState.rangeDate.length ? moment(formState.rangeDate[0]).format('YYYY-MM-DD') + ' 00:00:00' : '',
        endTime: formState.rangeDate.length ? moment(formState.rangeDate[1]).format('YYYY-MM-DD') + ' 23:59:59' : '',
        curPage: tableState.curPage,
        pageSize: tableState.pageSize
      };
      delete params.rangeDate;

      const { data, success } = await api.getWarningList(params);
      if (success) {
        tableState.dataSource = data.records;
        tableState.total = data.total;
      }
    };

    // 创建播放器
    const createPlayer = () => {
      playerState.player = new HlsPlayer({
        id: 'mse-video',
        url: videoUrl.value,
        autoplay: true
      });
      document.getElementById('mse-video').style.width = '100%';
    };

    // 销毁播放器
    const destroyPlayer = () => {
      console.log('player==', playerState.player);
      playerState.player.destroy();
    };

    const handleCancel = () => {
      visible.value = false;
      destroyPlayer();
    };

    // 查看详情
    const showDetailModal = () => {};

    const handlePageChange = page => {
      tableState.curPage = page.current;
      getWarningList();
    };

    // 查看视频
    const view = row => {
      console.log(row);
      modal.value = {
        video: true,
        title: '查看视频',
        content: ''
      };
      visible.value = true;
      videoUrl.value = row.text;
      nextTick(() => {
        createPlayer();
      });
    };

    const search = () => {
      tableState.curPage = 1;
      getWarningList();
    };

    const reset = () => {
      Object.assign(formState, {
        rangeDate: [],
        // 案件描述
        description: '',
        // 视频编号
        videoCode: '',
        // 违法类型
        illegalIds: [],
        // 转发平台
        platIds: [],
        // 工单状态
        type: null
      });
      // Object.keys(formState).map(key => (formState[key] = ''));
      search();
    };

    const export_click = () => {
      const params = {
        ...formState,
        startTime: formState.rangeDate.length ? moment(formState.rangeDate[0]).format('YYYY-MM-DD') + ' 00:00:00' : '',
        endTime: formState.rangeDate.length ? moment(formState.rangeDate[1]).format('YYYY-MM-DD') + ' 23:59:59' : ''
      };
      delete params.rangeDate;
      console.log(params);
      api.exportWarning(params).then(res => {
        const blob = new Blob([res]);
        const fileName = `预警列表-${FormatTime(new Date())}.xlsx`;
        const elink = document.createElement('a');
        elink.download = fileName;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      });
    };


    // 发起预警
    const earlyWarningObj = ref({
      // 描述
      description: '',
      // 图片
      evidencePicture: '',
      // 非法类型
      illegalType: null,
      // 画框坐标
      top: null,
      left: null,
      width: null,
      height: null,
      // 列表选择
      platform: null,
      // 直播间ID
      roomId: '',
      
      id: null,
      departmencode: null,

    });
    // 违法类型
    const TypesViolationList = ref([]);
    const getTypesViolation = async () => {
      const { data } = await api.getTypesViolation();
      TypesViolationList.value = data;
    };
    const edit = (row) => {
      getTypesViolation();
      modal.value = {
        earlyWarning: true,
      };
      visible.value = true;
      console.log( row.record)
      earlyWarningObj.value.evidencePicture = row.record.base64
      earlyWarningObj.value.roomId = row.record.roomId
      earlyWarningObj.value.id = row.record.id
    }

    const modifyImage = ref(false);
    let startX,
      startY,
      ctx,
      isdown = 0,
      canvas,
      imageUrl;
    const amplification = () => {
      modifyImage.value = true;
      nextTick(() => {
        canvas = document.getElementById('canvas');
        let img = new Image();
        img.src = earlyWarningObj.value.evidencePicture;
        let context = canvas.getContext('2d');
        ctx = context;
        img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height;
          img.classList.add('box-canvas-img');
          document.getElementById('box-canvas').appendChild(img);
        };
      });
    };
    // 矩型
    const drawIs = ref(false);
    function drawRect(e) {
      if (isdown) {
        console.log(1)
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.beginPath();
        ctx.lineWidth = '3';
        ctx.strokeStyle = '#FF0000';
        ctx.strokeRect(startX, startY, e.offsetX - startX, e.offsetY - startY);

        earlyWarningObj.value.left = startX;
        earlyWarningObj.value.top = startY;
        earlyWarningObj.value.width = e.offsetX - startX;
        earlyWarningObj.value.height = e.offsetY - startY;
      }
    }
    const mouseDown = e => {
      isdown = 1;
      drawIs.value = false;
      startX = e.offsetX; // 鼠标落下时的X
      startY = e.offsetY; // 鼠标落下时的Y
    };
    const mouseUp = e => {
      isdown = 0;
      drawIs.value = true;
    };
    const mouseMove = e => {
      if (drawIs.value) {
        return false;
      }
      drawRect(e);
    };

    const okObj = {
      earlyWarning: async () => {
        let isGo = true;
        earlyWarningObj.value.type = 1
        Object.keys(earlyWarningObj.value).forEach(valueKey => {
          if (!earlyWarningObj.value[valueKey]) {
            if (valueKey == 'departmencode') {
            } else if (valueKey == 'top' || valueKey == 'left' || valueKey == 'height' || valueKey == 'width') {
              message.error('请画框');
            } else {
              message.error('请填写完整');
              isGo = false;
            }
          }
        });
        if (!isGo) {
          return false;
        }
        try {
          const { success } = await api.setWarningList(earlyWarningObj.value);
          if (success) message.success('预警成功');
          earlyWarningObj.value = {
            // 描述
            description: '',
            // 图片
            evidencePicture: '',
            // 非法类型
            illegalType: null,
            // 画框坐标
            top: null,
            left: null,
            height: null,
            width: null,
            // 列表选择
            platform: null,
            // 直播间ID
            roomId: '',
            type: null,
            id: null,
            departmencode: null,
          };
        } catch (error) {
          message.error('网络错误');
        }
        getWarningList();
        handleCancel();
      },
    };

    const invalidate = async () => {
      try {
        const { success } = await api.setWarningList({
            type: 3,
            id: earlyWarningObj.value.id
          });
          if (success) message.success('作废成功');
        } catch (error) {
          message.error('网络错误');
        }
      getWarningList();
      handleCancel();
    }

    const ok = () => {
      okObj[Object.keys(modal.value).pop()]();
    };

    const departmencode_show = ref(false);
    const handleChange = value => {
      if (value == 3) {
        departmencode_show.value = true;
      } else {
        departmencode_show.value = false;
      }
    };

    

    return {
      visible,
      videoUrl,
      ...toRefs(formState),
      getWarningList,
      handlePageChange,
      search,
      reset,
      view,
      playerState,
      createPlayer,
      destroyPlayer,
      handleCancel,
      showDetailModal,
      ...toRefs(tableState),
      export_click,
      edit,
      modal,
      earlyWarningObj,
      TypesViolationList,
      amplification,
      mouseDown,
      modifyImage,
      mouseUp,
      mouseMove,
      ok,
      departmencode_show,
      handleChange,
      invalidate
    };
  }
});
</script>

<style lang="less" scoped>
.wrap {
  padding: 15px 20px;
  flex-wrap: wrap;
  .title {
    font-weight: bold;
    font-size: 20px;
  }
  :deep(.ant-form) {
    // display: flex;
    // justify-content: center;
    margin: 15px 0;
    .box {
      display: flex;
      .ant-form-item {
        margin-right: 10px;
      }
    }
    .btn-group {
      flex: 1;
      .ant-btn {
        margin-left: 20px;
      }
    }
  }
  :deep(.ant-table-wrapper) {
    margin-top: 15px;
    .ant-table {
      .avatar {
        width: 50px;
        height: 50px;
        object-fit: cover;
      }
      .action > span {
        margin-right: 14px;
        display: inline-block;
        padding: 0px 10px;
        border-radius: 3px;
        font-size: 12px;
        height: 25px;
        line-height: 25px;
      }

      .action {
        display: flex;
        span {
          cursor: pointer;
          color: #fff;
        }
        .edit {
          background-color: #007fff;
        }
      }
    }
  }
}

.earlyWarning-box {
  ::v-deep(.captcha-modal) {
    .ant-modal-content {
      padding: 0;
      .ant-modal-body {
        // background-color: #fff;
        padding: 56px 40px 36px;
      }
      .itemBox {
        display: flex;
        span {
          flex: 1;
          display: block;
          color: #666666;
          font-size: 14px;
        }

        .itemRight {
          flex: 5;
        }
        textarea {
          padding: 0 !important;
          border-radius: 5px;
          height: 150px;
          resize: none;
        }
        textarea::placeholder {
          padding: 2px;
        }
        .ant-select-selector {
          border-radius: 5px;
        }
      }
      .itemBox:nth-child(1) {
        span {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .itemBox:nth-child(2) {
        margin: 20px 0;
      }
      .itemBox:nth-child(3) {
        div {
          height: 144px;
          border: 1px solid #d8d8d8;
          border-radius: 5px;
        }
      }
      .itemBox:nth-child(4) {
        margin: 20px 0;
        span {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .itemBox:nth-child(5) {
        span {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
#box-canvas {
  margin-bottom: 10px;
  position: relative;
}
::v-deep .box-canvas-img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: auto;
  height: auto;
}

.reload-db {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.canvas {
  border: 1px solid #d3d3d3;
}


</style>
