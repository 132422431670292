<template>
  <div ref="madal" class="captcha-modal-box">
    <a-modal
      v-model:visible="visible"
      :title="title"
      @cancel="handleCancel"
      :getContainer="getContainer"
      class="captcha-modal"
    >
      <slot name="content"></slot>
      <template #footer>
        <slot name="footer"></slot>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { inject, ref } from 'vue';
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  setup() {
    const madal = ref(null);
    const visible = inject('visible');
    const handleCancel = () => {
      visible.value = false;
    };
    // 获取修改样式
    const getContainer = () => madal.value;

    return {
      handleCancel,
      visible,
      madal,
      getContainer
    };
  }
};
</script>
